import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Image from '@nubank/nuds-web/components/Image/Image';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';
import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import { useParallax } from 'react-scroll-parallax';

const StyledLink = styled(Link)`
  margin-right: 0;
  
  ${breakpointsMedia({
    md: css`
      margin-right: 32px;
    `,
  })}

  &:last-child {
    margin-right: 0;
  }
`;

function Xpeer({ mgm }) {
  const { formatMessage } = useIntl();
  const { ref } = useParallax({ speed: 30, easing: [0, 1, 0.9, 0.2] });

  return (
    <Box
      tag="section"
      display="flex"
      flexDirection="column"
      marginTop="32x"
      alignItems="center"
      marginBottom="12x"
      paddingHorizontal={{ xs: '0', lg: mgm ? '56px' : '8vw', xl: mgm ? '20x' : '16vw' }}
    >

      <Grid
        paddingLeft="0"
        paddingRight="0"
      >
        {/* BACK IMG */}

        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '2 / span 10' }}
          gridRow="1"
        >
          <Box
            ref={ref}
            zIndex="-2"
            position="relative"
          >
            <Image
              alt="Dos mujeres sonriendo sostienen la tarjeta Nu con su mano"
              srcSet={{
                xs: 'lite-version/xpeers.jpg',
                lg: mgm ? 'mgm/xpeers/xpeers-mgm-lg.png' : 'lite-version/xpeers-lg.jpg',
              }}
              width="100vw"
              height="100%"
              loading="lazy"
              webP
            />
          </Box>

        </Grid.Child>

        {/* CONTENT BOX */}

        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '4 / span 6' }}
          gridRow="1"
        >
          <Box
            background="linear-gradient(137.07deg, #9F2DEB 42.83%, #6200A3 86.85%)"
            borderRadius="12px"
            paddingHorizontal={{
              xs: '8x',
              md: '12x',
              lg: mgm ? '8x' : '12x',
            }}
            paddingVertical="42px"
            marginHorizontal={{
              xs: '8x',
              lg: mgm ? '5vw' : '0x',
              xl: mgm ? '7vw' : '0x',
            }}
            marginTop={{ xs: '44%', lg: mgm ? '5%' : '30%' }}
            marginBottom="60px"
            display="flex"
            flexDirection="column"
            alignItems={{ xs: 'left', md: 'center' }}
          >
            <Typography
              variant="heading3"
              intlKey="HOME.LITE_VERSION.XPEER.TITLE"
              marginBottom="8x"
              color="white"
              textAlign={{ xs: 'left', md: 'center' }}
            />

            <Typography
              variant="subtitle1"
              intlKey="HOME.LITE_VERSION.XPEER.SUBTITLE"
              marginBottom="2x"
              color="white"
              textAlign={{ xs: 'left', md: 'center' }}
              whiteSpace={{ xs: 'normal', lg: 'pre-line' }}
            />

            {/* SOCIAL LOGOS */}

            <Box
              marginVertical="8x"
              display="flex"
              justifyContent={{ xs: 'space-between', md: 'center', lg: 'end' }}
              marginLeft="-0.5rem"
            >
              <StyledLink
                href={formatMessage({ id: 'FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK' })}
                color="white"
                iconProps={{ name: 'instagram' }}
              />
              <StyledLink
                href={formatMessage({ id: 'FOOTER.SOCIAL_MEDIA.TIKTOK.LINK' })}
                color="white"
                iconProps={{ name: 'tiktok' }}

              />
              <StyledLink
                href={formatMessage({ id: 'FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK' })}
                color="white"
                iconProps={{ name: 'facebook' }}
              />
              <StyledLink
                href={formatMessage({ id: 'FOOTER.SOCIAL_MEDIA.TWITTER.LINK' })}
                color="white"
                iconProps={{ name: 'twitter' }}
              />
              <StyledLink
                href={formatMessage({ id: 'FOOTER.SOCIAL_MEDIA.YOUTUBE.LINK' })}
                color="white"
                iconProps={{ name: 'youtube' }}
              />
              <StyledLink
                href={formatMessage({ id: 'FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK' })}
                color="white"
                iconProps={{ name: 'linkedin' }}
              />
            </Box>

            <Link
              intlKey="HOME.LITE_VERSION.PRESS.CONTACT"
              target="_blank"
              rel="noopener"
              href="mailto:ayuda@nu.com.mx"
              color="white"
              typographyProps={{ variant: 'subtitle1' }}
            />

          </Box>
        </Grid.Child>

      </Grid>
    </Box>
  );
}

Xpeer.defaultProps = {
  mgm: false,
};

Xpeer.propTypes = {
  mgm: PropTypes.bool,
};

export default Xpeer;
