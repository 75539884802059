import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import styled from 'styled-components';
import Link from '@nubank/nuds-web/components/Link/Link';
import { useBreakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';

import { useDynamicYieldValues } from '../../../utils/dynamicYield';

const StyledLink = styled(Link)`
  font-size: 16px;
`;

const informationBuilder = dynamicYieldValues => ([
  {
    id: '1',
    label: 'COMMON.CUENTA.YEARLY_RATE.LABEL',
    description: 'COMMON.CUENTA.YEARLY_RATE.DESCRIPTION',
    values: dynamicYieldValues,
  },
  {
    id: '2',
    label: 'COMMON.CUENTA.GAT_NOMINAL.LABEL',
    description: 'COMMON.CUENTA.GAT_NOMINAL.DESCRIPTION',
    disclaimer: 'COMMON.CUENTA.GAT_NOMINAL.DISCLAIMER',
    values: dynamicYieldValues,
  },
  {
    id: '3',
    label: 'COMMON.CUENTA.GAT_REAL.LABEL',
    description: 'COMMON.CUENTA.GAT_REAL.DESCRIPTION',
    disclaimer: 'COMMON.CUENTA.GAT_REAL.DISCLAIMER',
    values: dynamicYieldValues,
  },
]);

const CuentaRates = () => {
  const { formatMessage } = useIntl();
  const { values: dynamicYieldValues, textTemplates } = useDynamicYieldValues();
  const information = informationBuilder(dynamicYieldValues);
  const notMobile = useBreakpointsMediaUp('md') ? 'heading4' : 'heading3';

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={{ xs: 'flex-start', md: 'center' }}
      alignItems={{ xs: 'flex-start' }}
    >
      {information.map(el => (
        <Box
          key={el.id}
          marginVertical={{ xs: '6x' }}
          display="flex"
          flexDirection="column"
          justifyContent={{ xs: 'flex-start', md: 'center' }}
          alignItems={{ xs: 'flex-start' }}
        >
          {el.label && (
          <Typography
            variant="heading4"
            textAlign={{ xs: 'left' }}
            maxWidth={{ xs: 'auto', md: '350px', lg: '600px' }}
          >
            {`${formatMessage({ id: el.label }, el.values)}`}
          </Typography>
          )}

          <Typography
            variant={notMobile}
            tag="span"
            color="#7B4DD6"
            textAlign={{ xs: 'left' }}
          >
            {`${formatMessage({ id: el.description }, el.values)}`}
          </Typography>

          {el.disclaimer && (
          <Typography
            variant="paragraph1"
            whiteSpace="pre-line"
            marginTop="4x"
            width={{ xs: '95%', md: '90%', lg: '100%' }}
            textAlign={{ xs: 'left' }}
          >
            {`${formatMessage({ id: el.disclaimer }, el.values)}`}
          </Typography>
          )}
        </Box>
      ))}

      <Typography
        variant="paragraph1"
        marginTop="8x"
        marginBottom="4x"
        width={{ xs: '95%', md: '90%', lg: '100%' }}
        textAlign={{ xs: 'left' }}
      >
        {`Valores calculados el ${textTemplates.VALUES_CALCULATED_AT}.\n\n Monto mínimo de ahorro ${textTemplates.FROZEN_MINIMUM_SAVINGS_AMOUNT}. Vigencia al ${textTemplates.VALID_UNTIL}. \n\n Producto garantizado por el Fondo de Protección hasta por 25,000 UDIs.`}
      </Typography>

      <Typography
        intlKey="COMMON.CUENTA.LEGAL.TERMS_AND_CONDITIONS"
        variant="paragraph1"
        marginBottom="4x"
        width={{ xs: '95%', md: '90%', lg: '100%' }}
        textAlign={{ xs: 'left' }}
        intlValues={{
          termsAndConditionsLink: (
            <StyledLink
              variant="paragraph1"
              href="/rendimiento/terminosycondiciones"
              target="_blank"
              rel="noopener noreferrer"
              intlKey="COMMON.CUENTA.LEGAL.TERMS_AND_CONDITIONS_LINK"
              color="black"
            />
          ),
        }}
      />

    </Box>

  );
};

export default CuentaRates;
